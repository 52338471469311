let prod = {
  endpoint: 'https://sync.actualbudget.com/account',
  stripeKey: 'pk_live_Da2Y1j4eyl0N1ua63T5AhU8U'
};

let dev = {
  endpoint: 'https://sync-staging.actualbudget.com/account',
  stripeKey: 'pk_test_1m0Bj2VPXb5bNlSFlx39MZtV'
};

export default process.env.GATSBY_APP_ENVIRON === 'dev' ? dev : prod;
