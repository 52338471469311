import { navigate } from 'gatsby';

export function getTempId() {
  let m = window.location.search.match(/tempId=(.*)/);
  if (m) {
    sessionStorage.setItem('tempId', m[1]);
    return m[1];
  } else {
    return sessionStorage.getItem('tempId');
  }
}

export function handleError(res) {
  if (res.status === 'error') {
    if (res.reason === 'unauthorized') {
      switch (res.details) {
        case 'token-expired':
          navigate('/account/expired');
          return true;
        case 'token-not-found':
        default:
          navigate('/account/login');
          return true;
      }
    }
  }
  return false;
}
